// ** Initial State
const initialState = {
    feedbacks: localStorage.getItem('feedbacks') ? JSON.parse(localStorage.getItem('feedbacks')) : [],
    officeFeedbacks: localStorage.getItem('officeFeedbacks') ? JSON.parse(localStorage.getItem('officeFeedbacks')) : [],
    total: 1,
    params: {},
    selectedFeedback: null,
    last_page: 1
  }

  const feedbacks = (state = initialState, action) => {  
    switch (action.type) {    
      case 'GET_FEEDBACKS_DATA':
        localStorage.setItem('feedbacks', JSON.stringify(action.data.results))
        return {
          ...state,
          feedbacks: action.data.results,
          total: action.data.count,
          last_page: action.data.last_page
        }
      case 'GET_FEEDBACK':
        return { ...state, selectedFeedback: action.selectedFeedback }
      case 'GET_FEEDBACKS_BY_OFFICE':
        localStorage.setItem('officeFeedbacks', JSON.stringify(action.data))
        return { ...state, officeFeedbacks: action.data }
      default:
        return { ...state }
    }
  }
  export default feedbacks
  