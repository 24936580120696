// **  Initial State
const initialState = {
  hospital: localStorage.getItem('hospital') ? localStorage.getItem('hospital') : '',
  abbreviation: localStorage.getItem('abbreviation') ? localStorage.getItem('abbreviation') : '',
  hospital_id: localStorage.getItem('hospital_id') ? localStorage.getItem('hospital_id') : 0,
  hospital_logo: localStorage.getItem('hospital_logo') ? localStorage.getItem('hospital_logo') : '',
  hospital_poc_email: localStorage.getItem('hospital_poc_email') ? localStorage.getItem('hospital_poc_email') : ''
}

const generalReducer = (state = initialState, action) => {
  switch (action.type) {

    case 'CHANGE_HOSPITAL':
      localStorage.setItem('hospital', action.hospital)
      localStorage.setItem('abbreviation', action.abbreviation)
      localStorage.setItem('hospital_id', action.hospital_id)
      localStorage.setItem('hospital_logo', action.hospital_logo)
      localStorage.setItem('hospital_poc_email', action.hospital_poc_email)
      localStorage.removeItem('auth')
      return { ...initialState, ...action }

    default:
      return state
  }
}

export default generalReducer
