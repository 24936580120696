import { paginateArray } from "../../../../../utility/Utils"

// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: {},
  selectedAccount: null
}

const accounts = (state = initialState, action) => {
  let allData = {}
  switch (action.type) {
   
    case 'GET_ACCOUNTS_DATA':
      allData = action.allData
      const { q = '', perPage = 10, page = 1 } = action.params
      const queryLowered = q
      // const queryLowered = q.toLowerCase()
      const filteredData = allData
      // const filteredData = allData.filter(
      //   account => (
      //     account.organization?.toLowerCase().includes(queryLowered) || account.email.toLowerCase().includes(queryLowered)) 
      // )
      return {
        ...state,
        data: paginateArray(filteredData, perPage, page),
        total: filteredData.length,
        params: action.params,
        allData: action.allData
      }    
    case 'GET_ACCOUNT':
      return { ...state, selectedAccount: action.selectedAccount }    
    case 'UPDATE_ACCOUNT':
      return { ...state, allData: action.allData }
    case 'ADD_ACCOUNT':
      return { ...state, allData: action.allData }
    case 'DELETE_ACCOUNT':
      return { ...state, allData: action.allData }
    case 'OPEN_ACCOUNT_DELETE_MODAL':
      return { ...state, del_modal_status: action.del_modal_status }
    default:
      return { ...state }
  }
}
export default accounts
