import moment from "moment-timezone";
import { Fragment } from "react";
import Avatar from "@components/avatar";
import { Check, X } from "react-feather";
import { Slide, toast } from "react-toastify";

export const reduceString = (data, n = 60) => {
  if (data?.length > n) {
    let s = data.substr(0, n);
    s = `${s}...`;
    return s;
  } else {
    return data;
  }
};
export const dateToYMD = (date) => {
  const d = date.getDate();
  const m = date.getMonth() + 1;
  const y = date.getFullYear();
  return `${y}-${m <= 9 ? `0${m}` : m}-${d <= 9 ? `0${d}` : d}`;
};
export const getDateTimeString = (date) => {
  if (!date) {
    return "";
  }

  const o_r = moment(date).tz("America/Chicago").format("YYYY-MM-DD HH:mm:ss");
  return o_r;
};

export const getDateString = (date) => {
  if (!date) {
    return "";
  }

  const o_r = moment(date).tz("America/Chicago").format("YYYY-MM-DD");
  return o_r;
};
export const getTimeString = (date) => {
  if (!date) {
    return "";
  }
  const o_r = moment(date).tz("America/Chicago").format("hh:mm:ss");
  return o_r;
};

// Example of how you might convert the release_date and release_time to an ISO string
// Note: This assumes your release_time is in a format like "HH:MM AM/PM"
// You might need to adjust the conversion logic based on your actual time format and timezone considerations
export const convertToISODateTime = (date, time) => {
  const [hours, minutes, modifier] = time.match(/(\d+):(\d+) (\w+)/).slice(1);
  let hours24 = parseInt(hours, 10);
  if (modifier === "PM" && hours24 < 12) {
    hours24 += 12;
  } else if (modifier === "AM" && hours24 === 12) {
    hours24 = 0;
  }
  // Assuming the date is in local timezone and converting it to ISO string without changing the timezone
  return new Date(
    `${date}T${String(hours24).padStart(2, "0")}:${minutes}:00`
  ).toISOString();
};

// Function to extract the date part in YYYY-MM-DD format from an ISO date-time string
export const extractDateFromISO = (isoDateTime) => {
  const date = new Date(isoDateTime);
  // Getting the individual date components
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // getMonth() is zero-based
  const day = String(date.getDate()).padStart(2, "0");
  // Formatting the date in YYYY-MM-DD format
  return `${year}-${month}-${day}`;
};

// Function to extract the time part in HH:MM AM/PM format from an ISO date-time string
export const extractTimeFromISO = (isoDateTime) => {
  const date = new Date(isoDateTime);
  // Getting the individual time components
  let hours = date.getHours();
  const minutes = String(date.getMinutes()).padStart(2, "0");
  // Converting 24-hour time to 12-hour format with AM/PM
  const modifier = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  // Formatting the time in HH:MM AM/PM format
  return `${String(hours).padStart(2, "0")}:${minutes} ${modifier}`;
};

export const calculateRemainingTime = (startTimestamp, endTimestamp) => {
  // Make sure the start timestamp is earlier than the end timestamp
  if (startTimestamp > endTimestamp) {
    [startTimestamp, endTimestamp] = [endTimestamp, startTimestamp];
  }

  // Calculate the difference in milliseconds
  const difference = endTimestamp - startTimestamp;

  // Calculate days, hours, minutes, and seconds
  const days = Math.floor(difference / (1000 * 60 * 60 * 24));
  const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
  const minutes = Math.floor((difference / (1000 * 60)) % 60);
  const seconds = Math.floor((difference / 1000) % 60);

  return { days, hours, minutes, seconds };
};

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = (obj) => Object.keys(obj).length === 0;
export const isChildObjEmpty = (obj) => {
  for (const [key, value] of Object.entries(obj)) {
    if (!value || value.length === 0) {
      return true;
    }
  }
  return false;
};

// ** Returns K format from a number
export const kFormatter = (num) =>
  num > 999 ? `${(num / 1000).toFixed(1)}k` : num;

// ** Converts HTML to string
export const htmlToString = (html) => html.replace(/<\/?[^>]+(>|$)/g, "");

// ** Checks if the passed date is today
const isToday = (date) => {
  const today = new Date();
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  );
};

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (
  value,
  formatting = { month: "short", day: "numeric", year: "numeric" }
) => {
  if (!value) return value;
  return new Intl.DateTimeFormat("en-US", formatting).format(new Date(value));
};

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value);
  let formatting = { month: "short", day: "numeric" };

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: "numeric", minute: "numeric" };
  }

  return new Intl.DateTimeFormat("en-US", formatting).format(new Date(value));
};

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => {
  const auth = localStorage.getItem("auth")
    ? JSON.parse(localStorage.getItem("auth"))
    : {};
  if (auth?.hasOwnProperty("accessToken")) {
    return true;
  }
  return false;
};

export const getUserOffice = () => {
  const auth = localStorage.getItem("auth")
    ? JSON.parse(localStorage.getItem("auth"))
    : {};
  if (auth?.office?.id) {
    return auth.office.id;
  }
  return null;
};
export const getCommonUser = () => JSON.parse(localStorage.getItem("auth"));
export const getAuthUser = () => {
  if (isUserLoggedIn()) {
    return JSON.parse(localStorage.getItem("auth"));
  } else {
    return null;
  }
};
export const getUserRole = () => {
  const auth = JSON.parse(localStorage.getItem("auth"));

  return auth?.role || "";
};
export const getAuthUserRole = () => {
  if (isUserLoggedIn()) {
    const auth = getAuthUser();

    return auth?.role || "";
  }
  return "";
};

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = (user) => {
  if (user.role === "administrator") return "/admin/dashboard";
  if (user.role === "account_manager") return "/am/dashboard";
  if (user.role === "patient") return "/patient/dashboard";
  if (!user.office?.hasOwnProperty("id")) {
    return "/officeselect";
  }
  return "/login";
};

// ** React Select Theme Colors
export const selectThemeColors = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: "#7367f01a", // for option hover bg-color
    primary: "#7367f0", // for selected option bg-color
    neutral10: "#7367f0", // for tags bg-color
    neutral20: "#ededed", // for input border-color
    neutral30: "#ededed", // for input hover border-color
  },
});

export const USER_ROLE_PATIENT = "patient";
export const USER_ROLE_STAFF = "staff";

export const CASE_INSURANCED = "insuranced";
export const CASE_NO_INSURANCED = "no_insuranced";
export const CASE_WORKERS_COMPENSATION = "workers_compensation";
export const CASE_MVA_INSURANCED = "mva_insuranced";
export const CASE_MVA_NO_INSURANCED = "mva_no_insuranced";

export const ToastContent = ({ is_success, message }) => (
  <Fragment>
    <div className="toastify-header pb-0">
      <div className="title-wrapper">
        {is_success ? (
          <Avatar size="sm" color="success" icon={<Check />} />
        ) : (
          <Avatar size="sm" color="danger" icon={<X />} />
        )}

        <h6 className="toast-title">{message}</h6>
      </div>
    </div>
  </Fragment>
);
export const onCopy = () => {
  toast.success(<ClipboardCopyToastSuccess />, {
    autoClose: 1000,
    hideProgressBar: true,
    closeButton: false,
  });
};
export const ClipboardCopyToastSuccess = () => (
  <Fragment>
    <div className="toastify-header pb-0">
      <div className="title-wrapper">
        <Avatar size="sm" color="success" icon={<Check />} />
        <h6 className="toast-title">Copied To Clipboard !</h6>
      </div>
    </div>
  </Fragment>
);
export const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;

  return {
    width,
    height,
  };
};
export const emailValidation = (email) => {
  const regex =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  if (!email || regex.test(email) === false) {
    return false;
  }
  return true;
};

export const returnLabelFromValue = (arrayOfObjs, value) => {
  const findItem = arrayOfObjs.find((item) => item.value === value);
  return findItem?.label;
};

export const paginateArray = (array, perPage, page) =>
  array.slice((page - 1) * perPage, page * perPage);

export const sortCompare = (key) => (a, b) => {
  const fieldA = a[key];
  const fieldB = b[key];

  let comparison = 0;
  if (fieldA > fieldB) {
    comparison = 1;
  } else if (fieldA < fieldB) {
    comparison = -1;
  }
  return comparison;
};

export const getRandomInt = (min, max) => {
  if (min > max) {
    const temp = max;
    /* eslint-disable no-param-reassign */
    max = min;
    min = temp;
    /* eslint-enable */
  }

  if (min <= 0) {
    return Math.floor(Math.random() * (max + Math.abs(min) + 1)) + min;
  }
  return Math.floor(Math.random() * max) + min;
};

export const randomDate = (start, end) => {
  const diff = end.getTime() - start.getTime();
  const newDiff = diff * Math.random();
  const date = new Date(start.getTime() + newDiff);
  return date;
};
