import { paginateArray } from "../../../../../utility/Utils"

// ** Initial State
const initialState = {
  updates: [],
  data: [],
  total: 1,
  params: {},
  selectedUpdate: null
}

const updates = (state = initialState, action) => {
  let updates = {}
  switch (action.type) {
    case 'ADD_UPDATE':
      return { ...state, updates: action.updates }
    case 'GET_UPDATES_DATA':
      updates = action.updates
      const { q = '', perPage = 10, page = 1 } = action.params
      const queryLowered = q.toLowerCase()
      const filteredData = updates.filter(
        update => (
          update.title.toLowerCase().includes(queryLowered) 
        )
      )
      return {
        ...state,
        data: paginateArray(filteredData, perPage, page),
        total: filteredData.length,
        params: action.params,
        updates: action.updates
      }    
    case 'GET_UPDATE':
      return { ...state, selectedUpdate: action.selectedUpdate }    
    case 'DELETE_UPDATE':
      return { ...state, updates: action.updates }
    case 'OPEN_UPDATE_DELETE_MODAL':
      return { ...state, del_modal_status: action.del_modal_status }
    default:
      return { ...state }
  }
}
export default updates
