// **  Initial State
const initialState = {
  ...localStorage.getItem('auth') ? JSON.parse(localStorage.getItem('auth')) : {}
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    // case 'GET_PAYERS':
    //   localStorage.setItem('auth', JSON.stringify({ ...state, payers: action.payers }))
    //   return { ...state, payers: action.payers }
    case 'GET_OFFICES':
      localStorage.setItem('auth', JSON.stringify({ ...state, offices: action.offices }))
      return { ...state, offices: action.offices }
    case 'UPDATE_USER_DATA':
      const userData = action.data
      Object.assign(state, userData)
      localStorage.setItem('auth', JSON.stringify(state))
      return { ...state, message: action.message }
    case 'UPDATE_OFFICE':
      Object.assign(...state.offices, action.updated_office)
      localStorage.setItem('auth', JSON.stringify(state))
      return { ...state }
    case 'SET_OFFICE':
      localStorage.setItem('auth', JSON.stringify({ ...state, office: action.office }))
      return { ...state, office: action.office }

    case 'LOGIN':
      localStorage.setItem('auth', JSON.stringify({ ...state, ...action.data }))
      return {
        ...state,
        ...action.data
        // [action.config.storageTokenKeyName]: action[action.config.storageTokenKeyName],
        // [action.config.storageRefreshTokenKeyName]: action[action.config.storageRefreshTokenKeyName]
      }
    case 'LOGOUT':
      localStorage.removeItem('auth')
      localStorage.removeItem('isUpdateModel')
      localStorage.removeItem('officeFeedbacks')
      localStorage.removeItem('feedbacks')
      return {}

    case 'OPEN_OFFICE_DELETE_MODAL':
      return { ...state, del_modal_status: action.del_modal_status }

    default:
      return state
  }
}

export default authReducer
