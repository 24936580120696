// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import general from './general'
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import document from '@src/views/apps/document/store/reducer'
import patient from '@src/views/apps/patient/store/reducer'
import todo from '@src/views/apps/todo/store/reducer'
import accounts from '@src/views/apps/account/store/reducer'
import users from '@src/views/apps/user/store/reducer'
import updates from '@src/views/apps/update/store/reducer'
import staffs from '@src/views/apps/staff/store/reducer'
import feedbacks from '@src/views/pages/settings/store/reducer'


const rootReducer = combineReducers({
  general,
  auth,
  navbar,
  layout,
  patient,
  document,
  todo,
  users,
  updates,
  accounts,
  staffs,
  feedbacks
})

export default rootReducer
