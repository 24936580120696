
const initialState = {
  total: 0,
  params: {},
  allData: [],
  selectedData: {},
  loading: true
}

const documentReducer = (state = initialState, action) => {

  switch (action.type) {
    case 'GET_DOCUMENT_SETS':
      return {
        ...state,
        data: action.data.items,
        total:action.data.total,
        last_page: action.data.last_page,
        loading: action.loading
      }
    case 'GET_DOCUMENT_SET':
      return {
        ...state,
        selectedData: action.selectedData
      }
    case 'DELETE_DOCUMENT':
      return { ...state }
    case 'ADD_DOCUMENT':
      return { ...state }
    default:
      return { ...state }
  }
}
export default documentReducer
